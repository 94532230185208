import * as Sentry from '@sentry/vue';
import { emailSchema, tokenSchema } from '~/utils/schemas';

export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.server) return;

  const logInToken = localStorage.getItem('token');

  if (logInToken) {
    localStorage.removeItem('token');
  }

  const token = to.params.token;
  const email = to.query.email;

  try {
    emailSchema.parse(email);
    tokenSchema.parse(token);
  } catch {
    showError({
      statusMessage: 'Nieprawidłowy link do zmiany hasła. Spróbuj ponownie.',
    });
    Sentry.captureException(new Error('Invalid password reset link'));
  }
});
